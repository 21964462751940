import React from 'react';
import c8S from 'classnames';
import './button.css';

const Button = ({
  block = false,
  children,
  className,
  component: Component = 'button',
  size,
  variant = 'primary',
  ...othersProps
}) => (
  <Component
    className={c8S('btn outline-none focus:shadow-outline', className, {
      'btn-primary': variant === 'primary',
      'btn-primary-light': variant === 'primary-light',
      'bg-white': variant === 'white',
      'text-lg py-4': size === 'lg',
      'w-full': block,
    })}
    {...othersProps}
  >
    {children}
  </Component>
);

export default Button;
