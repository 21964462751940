import {Link} from 'gatsby';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import React from 'react';

import Button from './Button';

export default ({ctaLink = 'https://console.fleetfn.com'}) => (
  <section className="bg-white-gray mt-12 lg:mt-40 py-16 px-6 sm:px-0">
    <div className="container">
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <h2 className="text-2xl sm:text-4xl font-bold">
          Start building quickly and scale its free
        </h2>
        <div className="sm:mt-0 mt-8">
          <Button
            component="a"
            className="sm:mr-4"
            size="lg"
            href={ctaLink}
            onClick={() => {
              trackCustomEvent({
                category: 'Join the Private Beta now [CTA]',
                action: 'Click',
              });
            }}
          >
            Join the Private Beta now
          </Button>
          <Button
            component={Link}
            className="sm:mt-0 mt-4"
            size="lg"
            variant="primary-light"
            to="/docs"
          >
            Learn more
          </Button>
        </div>
      </div>
    </div>
  </section>
);
