import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import classnames from 'classnames';
import React from 'react';

export default ({theme}) => (
  <div className="w-full text-center mb-32">
    <p className="text-2xl font-normal mb-4">Don’t miss the next post!</p>
    <div className="inline-flex w-full sm:w-3/6 lg:items-center flex-col lg:flex-row">
      <form
        action="https://build.us4.list-manage.com/subscribe/post?u=3f38e464e35a23f4a87784e54&amp;id=505c56164b"
        method="post"
        className="flex flex-col w-full"
        target="_blank"
        noValidate
      >
        <div
          className={classnames(
            'flex flex-col sm:flex-row w-full sm:border sm:rounded sm:py-2 sm:px-2',
            {
              'sm:bg-dark sm:border-dark-light': theme === 'dark',
              'sm:bg-white sm:border-secondary': theme !== 'dark',
            }
          )}
        >
          <div className="w-full mb-4 sm:mb-0">
            <input
              className={classnames(
                'border rounded sm:border-none sm:rounded-none appearance-none block w-full text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:border-primary',
                {
                  'focus:bg-dark bg-dark border-dark-light': theme === 'dark',
                  'focus:bg-white border-secondary': theme !== 'dark',
                }
              )}
              name="EMAIL"
              placeholder="your@email.com"
              required
              type="email"
            />
            <input
              hidden
              aria-hidden
              type="text"
              name="b_3f38e464e35a23f4a87784e54_505c56164b"
              tabIndex="-1"
              value=""
            />
          </div>
          <div className="inline-flex sm:flex sm:flex-shrink-0">
            <button
              className="flex items-center bg-primary font-medium text-white box-shadow-primary py-3 px-5 leading-tight rounded text-md outline-none focus:shadow-outline"
              name="subscribe"
              onClick={() => {
                trackCustomEvent({
                  category: 'Sign up Newsletter',
                  action: 'Click',
                });
              }}
              type="submit"
            >
              Join
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
);
