import React from 'react';
import {Link} from 'gatsby';
import {Twitter} from 'react-feather';
import {graphql} from 'gatsby';
import classNames from 'classnames';
import './blog.css';

import CTA from '../components/CTA';
import Layout from '../components/layout';
import Newsletter from '../components/Newsletter';
import SEO from '../components/seo';

const externalPosts = [
  {
    author: [
      {
        frontmatter: {
          name: 'Matuzalém Teles',
          image:
            'https://pbs.twimg.com/profile_images/949820478239240192/XW-FueXr_400x400.jpg',
          url: 'https://twitter.com/MatuzalemTeles',
        },
      },
    ],
    readingTime: {
      text: '6 min read',
    },
    date: 'Jun 19, 2020',
    dateISO: '2020-06-19T03:00:00.000Z',
    excerpt:
      'In February this year, we announced Fleet (Formerly Hole), a FaaS platform built on Node.js to be faster than other platforms and to create a more faithful integration with the ecosystem...',
    external: true,
    imageUrl: '/images/blog/fleet-serverless-function-introduction.png',
    link: 'https://dev.to/fleet/fleet-serverless-function-introduction-5cle',
    origin: 'dev.to',
    title: 'Fleet Serverless Function Introduction',
  },
];

const getPosts = edges => {
  const posts = edges.map(({node}) => {
    const {
      excerpt,
      fields: {slug, date, dateISO, readingTime},
      frontmatter: {author, title, theme, imageUrl, archive},
    } = node;
    return {
      author,
      link: slug,
      title,
      theme,
      readingTime,
      imageUrl,
      date,
      dateISO,
      excerpt,
      archive,
    };
  });

  const postsAggregate = posts.concat(externalPosts);

  postsAggregate.sort((a, b) => new Date(b.dateISO) - new Date(a.dateISO));

  return postsAggregate;
};

const BlogPage = ({data: {allMarkdownRemark}}) => {
  const [firstPost, ...posts] = getPosts(allMarkdownRemark.edges);

  const PostLink = firstPost.external ? 'a' : Link;

  return (
    <Layout spacingTop={false}>
      <SEO title="Blog - Fleet Serverless function fastest" />
      <div className="relative mt-12 md:mt-24 px-6 lg:px-12">
        <div className="container flex flex-col">
          <section className="flex flex-col w-full">
            <div className="md:w-3/6">
              <h1 className="text-gray mb-2">BLOG</h1>
              <p className="text-3xl sm:text-4xl md:text-5xl leading-tight font-medium">
                Company Updates & Technology Articles
              </p>
              <a
                href="https://twitter.com/fleetfn"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray flex items-center mt-8 leading-relaxed"
              >
                Follow Us On Twitter{' '}
                <Twitter className="ml-2" color="#0076ff" size={22} />
              </a>
            </div>
          </section>
        </div>
        <div
          className={classNames('py-32 my-24', {
            'bg-white-gray': firstPost.theme !== 'dark',
            'bg-dark text-white': firstPost.theme === 'dark',
          })}
        >
          <div class="container">
            <article className="flex flex-col lg:flex-row items-center">
              <PostLink
                href={firstPost.link}
                className="w-3/4 lg:w-7/12"
                to={firstPost.link}
              >
                <img
                  className={classNames('rounded-md', {
                    'shadow-xl': firstPost.theme !== 'dark',
                  })}
                  alt={firstPost.title}
                  src={firstPost.imageUrl}
                />
              </PostLink>
              <header className="flex flex-col flex-1 pl-16 py-8">
                <h2 className="text-center lg:text-left mb-12 text-4xl leading-tight font-semibold">
                  <PostLink href={firstPost.link} to={firstPost.link}>
                    {firstPost.title}
                  </PostLink>
                </h2>
                <div className="flex items-center pt-6 mx-auto lg:mx-0">
                  <img
                    className="w-8 h-8 border-2 rounded-full border-secondary-200"
                    alt={firstPost.author[0].frontmatter.name}
                    src={firstPost.author[0].frontmatter.image}
                  />
                  <div className="text-sm pl-2">
                    <div class="font-medium">
                      {firstPost.author[0].frontmatter.name}
                    </div>
                    <div class="flex items-center text-gray">
                      <time dateTime={firstPost.dateISO}>{firstPost.date}</time>
                      <span class="mx-2 border-2 rounded-full"></span>
                      <span>{firstPost.readingTime.text}</span>
                    </div>
                  </div>
                </div>
              </header>
            </article>
          </div>
        </div>
        <div className="container">
          <div className="w-full">
            <Newsletter wFull />
            <div className="grid sm:grid-cols-3 grid-cols-1 gap-10">
              {posts.map((page, index) => {
                const Comp = page.external ? 'a' : Link;

                return (
                  <article
                    key={page.link}
                    className={classNames(
                      'flex flex-col bg-white rounded-lg border border-secondary-100 transition-cb-1 h-full hover:shadow-lg'
                    )}
                  >
                    {index < 3 && (
                      <Comp href={page.link} to={page.link}>
                        <img
                          className="rounded-t-md"
                          alt={page.title}
                          src={page.imageUrl}
                        />
                      </Comp>
                    )}
                    <header className="flex flex-col flex-1 px-6 py-6">
                      <h2 className="pb-3 text-2xl leading-tight font-semibold">
                        <Comp href={page.link} to={page.link}>
                          {page.title}
                        </Comp>
                      </h2>
                      {index < 3 && (
                        <p className="text-gray leading-relaxed">
                          {page.excerpt}
                        </p>
                      )}
                      <div className="flex items-center pt-6 mt-auto">
                        <img
                          className="w-8 h-8 border-2 rounded-full border-secondary-200"
                          alt={page.author[0].frontmatter.name}
                          src={page.author[0].frontmatter.image}
                        />
                        <div className="text-sm pl-2">
                          <div class="font-medium">
                            {page.author[0].frontmatter.name}
                          </div>
                          <div class="flex items-center text-gray">
                            <time dateTime={page.dateISO}>{page.date}</time>
                            <span class="mx-2 border-2 rounded-full"></span>
                            <span>{page.readingTime.text}</span>
                          </div>
                        </div>
                      </div>
                    </header>
                  </article>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/blog/"}}
      sort: {fields: [fields___date], order: DESC}
    ) {
      edges {
        node {
          timeToRead
          excerpt(pruneLength: 150)
          frontmatter {
            archive
            title
            imageUrl
            author {
              frontmatter {
                name
                image
              }
            }
            theme
          }
          fields {
            slug
            date(formatString: "MMMM DD, YYYY")
            dateISO: date
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
